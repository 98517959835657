.vetri-loader-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8); 
    z-index: 1000;
  }
  
 
  .vetri-loader {
    border: 8px solid #f1c40f;
    border-top: 8px solid #e74c3c; 
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1.5s linear infinite, pulse 2s infinite alternate;  
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0.6;
    }
  }
  
  .vetri-text {
    margin-top: 20px;
    font-family: 'Bebas Neue', sans-serif; 
    font-size: 30px;
    font-weight: bold;
    color: #f39c12;  
    text-transform: uppercase;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }
  
  .vetri-text {
    animation: textGlow 2s infinite alternate;
  }
  
  @keyframes textGlow {
    0% {
      color: #f39c12;
      text-shadow: 0 0 5px #e74c3c, 0 0 10px #e74c3c, 0 0 15px #f39c12;
    }
    100% {
      color: #e74c3c;
      text-shadow: 0 0 5px #f39c12, 0 0 10px #f39c12, 0 0 15px #e74c3c;
    }
  }
  