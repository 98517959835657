.sidebar-header img {
    width: 100%;
    max-width: 50px;
    height: auto;
}

.sidebar-header p {
    color: #a10101;
    font-size: 15px;
    font-weight: 600;
    white-space: nowrap;
}

.menu-items {
    margin-top: -7px;
}

.sidebar-header a {
    text-decoration: none;

}

.menu-items li {
    list-style: none;
    padding: 13px 16px;
}

.menu-items li a {
    white-space: nowrap;
    text-decoration: none;
    color: #000;
    font-size: 15px;
    font-weight: 500;
}


.menu-items li a.active {
    color: #a10101 !important;
}
@media only screen and (max-width:768px) {

.sidebarnavmenu {
    display: none;
} 

}
@media only screen and (max-width:1024px) {

.menu-items li a {
font-size: 11px;
}
}