/* @media only screen and (min-width:1200px) {
    .banner-carousel1 {
        background-image: url(/public/images/home-banner6.jpg);
        background-size: cover;
        width: 100%;
        height: auto;
        min-height: 700px;
        background-position: center;
        background-repeat: no-repeat;
    }

    .banner-carousel2 {
        background-image: url(/public/images/banner-hometvk2.jpg);
        background-size: cover;
        width: 100%;
        height: auto;
        min-height: 600px;
        background-position: center;
        background-repeat: no-repeat;
    }

    .banner-carousel3 {
        background-image: url(/public/images/home-tvk2.jpg);
        background-size: cover;
        width: 100%;
        height: auto;
        min-height: 600px;
        background-position: center;
        background-repeat: no-repeat;
    }

    .banner-carousel4 {
        background-image: url(/public/images/home-banner3.jpg);
        background-size: cover;
        width: 100%;
        height: auto;
        min-height: 600px;
        background-position: center;
        background-repeat: no-repeat;
    }


    .banner-carousel5 {
        background-image: url(/public/images/home-banner5.jpg);
        background-size: cover;
        width: 100%;
        height: auto;
        min-height: 600px;
        background-position: center;
        background-repeat: no-repeat;
    }

} */
@media only screen and (min-width:992px){
    .banner-carousel1 {
        background-image: url(/public/images/home-banner6.jpg);
        background-size: cover;
        width: 100%;
        height: 100%;
        background-position:center center;
        background-repeat: no-repeat;
    }
}

@media only screen and (min-width:1200px) {
   
    .banner-carousel1 {
        background-image: url(/public/images/home-banner7.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
        background-position:center center;
        background-repeat: no-repeat;
    }
    .banner-carousel2 {
        background-image: url(/public/images/banner-hometvk2.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
        background-position:center center;
        background-repeat: no-repeat;
    }

    .banner-carousel3 {
        background-image: url(/public/images/home-tvk2.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
        background-position:center center;
        background-repeat: no-repeat;
    }

    .banner-carousel4 {
        background-image: url(/public/images/home-banner3.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
        background-position:center center;
        background-repeat: no-repeat;
    }

    .banner-carousel5 {
        background-image: url(/public/images/home-banner5.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
      
        background-position:center center;
        background-repeat: no-repeat;
    }

    .banner-carousel6 {
        background-image: url(/public/images/home-banner6.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
      
        background-position:center center;
        background-repeat: no-repeat;
    }
    /* static/media/home-banner7 */
}
@media only screen and (min-width:1400px) {
   
    .banner-carousel1 {
        background-image: url(/public/images/home-banner7.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
        background-position:center center;
        background-repeat: no-repeat;
    }
    .banner-carousel2 {
        background-image: url(/public/images/banner-hometvk2.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
        background-position:center center;
        background-repeat: no-repeat;
    }

    .banner-carousel3 {
        background-image: url(/public/images/home-tvk2.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
        background-position:center center;
        background-repeat: no-repeat;
    }

    .banner-carousel4 {
        background-image: url(/public/images/home-banner3.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
        background-position:center center;
        background-repeat: no-repeat;
    }

    .banner-carousel5 {
        background-image: url(/public/images/home-banner5.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
      
        background-position:center center;
        background-repeat: no-repeat;
    }

    .banner-carousel6 {
        background-image: url(/public/images/home-banner6.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
      
        background-position:center center;
        background-repeat: no-repeat;
    }

    .banner-carousel7 {
        background-image: url(/public/images/home-banner8.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
      
        background-position:center center;
        background-repeat: no-repeat;
    }

}
li {
    list-style: none;
}



.copy p {

    color: #fff;

    z-index: 1;
    position: relative;
    width: 48%;
    text-align: center;
    margin: 0 auto;
    transform: translateY(70%);
}

.vijay-bg .container-fluid {
    position: relative;
    width: 100%;
    height: 250px;
}

@media only screen and (max-width: 1024px) {
    .header-sec form.form-btns {
        display: block !important;
    }

    .header-sec .joinbtn {
        margin-bottom: 15px !important;

    }

    .language-selector {
        width: 100%;
        margin-bottom: 15px !important;
        max-width: 95%;
        margin: 0 auto;
        display: block;
        text-align: center;
    }
}

.language-selector {
    text-align: center;

}

.hero {
    background-image: url(/public/images/banner-tvkimg.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.banner-carousel {
    padding-top: 52px;

}

.joinbtn {
    display: block;
    text-align: center;
    font-size: 15px;
    text-transform: uppercase;
    color: #fff;
    background-size: 200% auto;
    border-radius: 5px;
    transition: 0.5s;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
    background-image: linear-gradient(to right, #a10101 30%, #ffe723 100%, #a10101 50%);
    border: none;
    height: 40px;
    width: 100%;
    max-width: 150px;
    min-width: 150px;
    text-decoration: none;

}

.joinbtn:hover {
    background-position: right center;
}

.element-left-align {
    display: inline-block;
    position: relative;

    padding-bottom: 15px;
}

.element-left-align:after {
    content: '';
    position: absolute;

    left: 0;
    bottom: 0;
    height: 2px;
    background-image: linear-gradient(to right, #a10101 30%, #ffe723 100%, #a10101 50%);
    width: 0;

    border-radius: 10px;
    animation: expand 3s forwards;

}

/* Keyframes for the animation */
@keyframes expand {
    0% {
        width: 0;
    }

    100% {
        width: 90%;
    }
}



/* aboutsec */
.about-sec img {
    width: 100%;
    max-width: 700px;
    height: auto;
}


figure.card {
    position: relative;
    width: 100%;
    max-width: 400px;
    height: 200px;
    background-image: url(/public/images/vijayone.jpg);

    background-size: cover;
    transition: background 400ms ease;

    overflow: hidden;
    background-repeat: no-repeat;
    border: none;
}

.prioritestwo .card {
    position: relative;
    width: 100%;
    max-width: 400px;
    height: 200px;
    background-image: url(/public/images/vijaytwo.png);

    background-size: cover;
    transition: background 400ms ease;

    overflow: hidden;
    background-repeat: no-repeat;
    border: none;
}

.prioritesthree .card {
    position: relative;
    width: 100%;
    max-width: 400px;
    height: 200px;
    background-image: url(/public/images/vijaythree.png);

    background-size: cover;
    transition: background 400ms ease;

    overflow: hidden;
    background-repeat: no-repeat;
    border: none;
}

figure.card:hover figcaption {
    transform: translateY(0px);
}

figure.card--1:hover {
    background-position: 50% 100%;
}

figure.card--1 figcaption {
    bottom: 0;
    transform: translateY(80px);
}


figure.card--1 figcaption {
    width: 100%;
    height: 80px;
    padding: 15px 20px;
}



figure.card figcaption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    background-image: linear-gradient(to right, #a10101 30%, #ffe723 100%, #a10101 50%);
    transition: transform 400ms ease;
}

figure.card figcaption .info {
    color: #e2e2e2;

}

figure.card figcaption .info h5 {

    letter-spacing: 1px;
    margin-bottom: 2px;
}


figure.card figcaption .links {
    display: flex;
    justify-content: end;
    align-items: center;
}

figure.card figcaption .links a {
    text-decoration: none;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-left: 10px;

    opacity: 0.65;
    border-radius: 50%;

}

figure.card figcaption .links a svg {
    width: 20px;
    height: 20px;
}

figure.card figcaption .links a:hover {
    opacity: 1;
}

figure.card figcaption .links a:focus {
    outline: none;
}

figure.card figcaption .links a:focus::after {
    transform: scale(1);
    opacity: 1;
}

figure.card figcaption .links a::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0;
    background-image: linear-gradient(to right, #a10101 30%, #ffe723 100%, #a10101 50%);
    transform: scale(0.5);
    z-index: -1;
    transition: all 150ms ease;
}



@media screen and (min-width: 768px) and (max-width: 1024px) {


    .copy p {
        width: 70%;
    }

}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .banner-carousel1 {
        background-image: url(/public/images/home-banner7.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
        background-position:center center;
        background-repeat: no-repeat;
    }
    .banner-carousel2 {
        background-image: url(/public/images/banner-hometvk2.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
        background-position:center center;
        background-repeat: no-repeat;
    }

    .banner-carousel3 {
        background-image: url(/public/images/home-tvk2.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
        background-position:center center;
        background-repeat: no-repeat;
    }

    .banner-carousel4 {
        background-image: url(/public/images/home-banner3.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
        background-position:center center;
        background-repeat: no-repeat;
    }

    .banner-carousel5 {
        background-image: url(/public/images/home-banner5.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
      
        background-position:center center;
        background-repeat: no-repeat;
    }

    .banner-carousel6 {
        background-image: url(/public/images/home-banner6.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
      
        background-position:center center;
        background-repeat: no-repeat;
    }
    .banner-carousel7 {
        background-image: url(/public/images/home-banner8.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
      
        background-position:center center;
        background-repeat: no-repeat;
    }

}

@media screen and (min-width: 992px) and (max-width: 1024px) {
    .banner-carousel1 {
        background-image: url(/public/images/home-banner7.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
        background-position:center center;
        background-repeat: no-repeat;
    }
    .banner-carousel2 {
        background-image: url(/public/images/banner-hometvk2.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
        background-position:center center;
        background-repeat: no-repeat;
    }

    .banner-carousel3 {
        background-image: url(/public/images/home-tvk2.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
        background-position:center center;
        background-repeat: no-repeat;
    }

    .banner-carousel4 {
        background-image: url(/public/images/home-banner3.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
        background-position:center center;
        background-repeat: no-repeat;
    }

    .banner-carousel5 {
        background-image: url(/public/images/home-banner5.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
      
        background-position:center center;
        background-repeat: no-repeat;
    }

    .banner-carousel6 {
        background-image: url(/public/images/home-banner6.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
      
        background-position:center center;
        background-repeat: no-repeat;
    }

    .banner-carousel7 {
        background-image: url(/public/images/home-banner8.jpg);
        background-size: cover;
        width: 100%;
        height: 670px;
      
        background-position:center center;
        background-repeat: no-repeat;
    }
}

@media only screen and (max-width:500px) {

    .banner-carousel1 {

        background-image: url(/public/images/achivements-bannermobile8.jpg) !important;
        background-size: cover;
        height: 100%;
        width: 100%;
        background-position: center;
        min-height: 576px;
    }



    .banner-carousel2 {

        background-image: url(/public/images/banner-hometvkmobile.jpg) !important;
        background-size: cover;
        height: 100%;
        width: 100%;
        background-position: center;
        min-height: 576px;
    }

    .banner-carousel3 {

        background-image: url(/public/images/mobile-home-tvk2.jpg) !important;
        background-size: cover;
        height: 100%;
        width: 100%;
        background-position: center;
        min-height: 576px;
    }

    .banner-carousel4 {

        background-image: url(/public/images/homebanner4mobile.jpg) !important;
        background-size: cover;
        height: 100%;
        width: 100%;
        background-position: center;
        min-height: 576px;
    }

    .banner-carousel5 {

        background-image: url(/public/images/home-mobile7.jpg) !important;
        background-size: cover;
        height: 100%;
        width: 100%;
        background-position: center;
        min-height: 576px;
    }

    .banner-carousel4 {

        background-image: url(/public/images/homebanner4mobile.jpg) !important;
        background-size: cover;
        height: 100%;
        width: 100%;
        background-position: center;
        min-height: 576px;
    }


    .banner-carousel6 {

        background-image: url(/public/images/achivements-bannermobile.jpg) !important;
        background-size: cover;
        height: 100%;
        width: 100%;
        background-position: center;
        min-height: 576px;
    }

    .banner-carousel7 {

        background-image: url(/public/images/achivements-bannermobile.jpg) !important;
        background-size: cover;
        height: 100%;
        width: 100%;
        background-position: center;
        min-height: 576px;
    }
}




@media only screen and (max-width: 768px) {

    .contribute-us {
        height: 100% !important;
        min-height: 200px !important;
    }

    .hero {
        filter: blur(6px);
        background-size: cover;
        background-image: url(/public/images/mobile-vijay.jpg);

        background-position-x: 86%;



    }

    .copy p {
        transform: translateY(0);
        width: 100%;
        padding: 20px 3px;
        background-color: rgba(0, 0, 0, 0.4);
        top: 25px;
    }

    .joinbtn {
        margin: 0 auto;
        margin-bottom: 20px;
    }

    .contributebtns button {
        margin: 0 auto;
        display: block;
        margin-bottom: 10px;
    }


    .contribute-us {

        padding: 10px !important;

        height: 100%;
        color: #fff;
        min-height: 745px;
        background-position: 40%;
        object-fit: cover;
    }

    .gallery {
        --s: 84px !important;
        --g: 27px !important;

    }

    figure.card {
        margin: 0 auto;
        display: block;
    }

    .members {
        border-right: unset !important;
    }




    .header-sec nav.navbar.navbar-expand-lg.navbar-light {
        padding: 0;
    }

}

.countersec {
    background-color: #a10101;
    text-align: center;
    padding: 40px;
}

.countersec h5 {
    font-weight: 600;
    font-size: 25px;
    color: #ffff;
}

.members {
    border-right: 2px solid;
    border-image: linear-gradient(to top, #fff, #ffe723);
    /* Define the gradient */
    border-image-slice: 1;
}

.countersec p {
    color: #fff;
}

.ecnomic h5::after {
    height: 2px;
    background-color: #a10101;
    content: "";
    position: absolute;
    bottom: -9px;
    display: inline-block;
    width: 24px;
    left: 2px;
}

.ecnomic h5 {
    position: relative;
    font-size: 17px;
    font-weight: 600;

}

.svgico img {
    width: 50px;
    height: 44px;
}

/* .svgico {
    border: 1px solid #dbdbdb;
    border-radius: 50%;
    width: 53px;
    height: 43px;
    text-align: center;
    line-height: 41px;
    transition: color .3s;
}

.svgico:hover {
    background-color: #9b0101;
    transition: transform .2s, opacity .3s;
    transform: scale(1.3);

} */


.vision-content {
    width: 100%;
    max-width: 600px;
}

.contribute-us {
    background-image: url(/public/images/vijay-contribute.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 60px;
    width: 100%;
    height: 400px;
    margin-bottom: 50px;
    color: #fff;

}




.video-container iframe {
    width: 100%;
    max-width: 90%;
    height: 250px;
    border-radius: 5px;

}

.gallery {
    --s: 185px;
    --g: 21px;
    display: grid;
    margin: calc(var(--s) + var(--g));
}

.gallery>img {
    grid-area: 1/1;
    width: var(--s);
    aspect-ratio: 1.15;
    object-fit: cover;
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0 50%);
    transform: translate(var(--_x, 0), var(--_y, 0)) scale(var(--_t, 1));
    cursor: pointer;
    filter: grayscale(80%);
    transition: .2s linear;
    margin: 0 auto;
    display: block;
}

.gallery>img:hover {
    filter: grayscale(0);
    z-index: 1;
    --_t: 1.2;
}

.gallery>img:nth-child(1) {
    --_y: calc(-100% - var(--g))
}

.gallery>img:nth-child(7) {
    --_y: calc(100% + var(--g))
}

.gallery>img:nth-child(3),
.gallery>img:nth-child(5) {
    --_x: calc(-75% - .87*var(--g))
}

.gallery>img:nth-child(4),
.gallery>img:nth-child(6) {
    --_x: calc(75% + .87*var(--g))
}

.gallery>img:nth-child(3),
.gallery>img:nth-child(4) {
    --_y: calc(-50% - .5*var(--g))
}

.gallery>img:nth-child(5),
.gallery>img:nth-child(6) {
    --_y: calc(50% + .5*var(--g))
}


element {

    padding-bottom: 15px;
}

.element:after {
    content: '';
    display: block;
    margin: auto;
    height: 2px;
    background-image: linear-gradient(to right, #a10101 30%, #ffe723 100%, #a10101 50%);
    width: 0%;
    animation: underline 5s forwards;
    border-radius: 10px;
    margin-top: 10px;
}

@keyframes underline {
    0% {
        width: 0%;
    }

    /* No width at the start */
    100% {
        width: 50%;
    }

    /* Full width at the end */
}

@media (min-width: 769px) and (max-width: 1300px) {
    .gallery {
        --s: 145px !important;
    }
}

section.contribute-us p {
    width: 70%;
    margin: 0 auto;
}

@media only screen and (max-width: 500px) {
    .modal-dialog{
        margin: 0 !important;
    }
    .modal.show .modal-dialog{
        width: 100% !important;
        max-width: 300px !important;
    }
    .header-sec .navbar-brand img {
        max-width: 30px;
        max-height: 40px;
    }

    .video-container iframe {
        max-width: 100%;
    }

    h2 {
        font-size: 15px !important;
    }

    p {
        font-size: 12px !important;
    }
}

.loign svg {
    font-size: 27px;
    color: #a10101;
    display: none;
}

@media only screen and (max-width:768px) {
    .loign svg {
        display: block;

    }

    section.contribute-us p {
        width: 100%;
    }
    .address iframe{
        width: 100%;
        height: 100%;
    }
}

h2 {
    font-weight: 600;
}

.showmore-toggle {
    border: none;
    background-color: transparent;
    color: #9b0101;
    font-size: 15px;
    font-weight: 600;
}

.tvkkalagam {
    text-align: justify;
}

.tvk-headname {
    color: #a10101;

}

.ceremony h5{
    font-size: 25px;
    color: #a10101;
    padding: 12px 12px;
}

.joinbtn {
    background-image: linear-gradient(90deg,#a10101 30%,#ffe723 100%,#a10101 0);
    background-size: 200% auto;
    border: none;
    border-radius: 5px;
    box-shadow: 0 4px 6px #32325d1c,0 1px 3px #00000014;
    color: #fff;
    display: block;
    font-size: 15px;
    height: 40px;
    max-width: 250px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: .5s;
    width: 100%;
    margin: 10px auto;
  }
  .modal.show .modal-dialog{
    width: 100% !important ;
    max-width: 1000px !important;
  }
  .address iframe {
    margin: 0 auto;
    display: block;
    margin-bottom: 40px;
  }
  .vijay-image img {
    width: 100%;
}
.fade.hhhhh.live.modal .modal-dialog.modal-dialog-centered {
    width: 100%;
    max-width: 650px !important;
}