.footer-sec {
    background-color: #ededed;
    border-top: 1px solid #a10101;
}

.footer-page h4 {
    font-size: 22px;
    color: #a10101;
    white-space: nowrap;
}

.footer-sec a {
    text-decoration: none;
}

.footer-sec p {
    margin-bottom: 6px !important;
}

.last-footer {

    color: #fff;
    background-color: #a10101;
}

.number-footer {
    color: #a10101;
}

.footer-sec h6 {
    position: relative;
}

.footer-sec h6::after {
    background: none repeat scroll 0 0 transparent;
    bottom: -7px;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #a10101;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.footer-sec h6:hover::after {
    width: 30%;
    left: 0;
    right: 0;
}

@media screen and (max-height: 300px) {
    ul {
        margin-top: 40px;
    }

}

.last-footer {
    border-top: 2px solid #ffe723;

}

@media screen and (max-width: 767px) {
    .socailico {
        justify-content: center;
    }

    .socailico .social-button {

        height: 30px;
        width: 30px;

        line-height: 27px;
    }

    .socailico .social-button i {
        font: 15px;
    }
}


@media screen and (max-width: 768px) {
    .footer-sec p {
        font-size: 13px;
    }


    .footer-sec h6 {
        font-size: 13px;
    }


    .footer-page h4 {
        font-size: 17px;
    }
}

.socailico i {
    font-size: 20px;
    color: #fff;


}

.socailico .social-button {
    background-color: #a10101;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
}

.socailico .social-button:hover {
    background-color: transparent;
    border: 1px solid #a10101;

}

.socailico .social-button:hover .facebook {
    color: #0866ff;
}

.socailico .social-button:hover .twitter {
    color: #000;
}

.socailico .social-button:hover .linkedin {
    color: #0a66c2;
}

.socailico .social-button:hover .youtube {
    color: #ff0000;
}

.socailico .social-button:hover .instagrams {
    color: #c13584;
}

.thirukural {
    font-size: 13px;
    word-break: break-all;
    font-weight: 600;
}

.thirukural span {
    font-weight: 600;
    color: #a10101;
}