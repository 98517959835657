:root
{
    --body-bg:#f5f5f5;
    --text-color:#000;
}

     
[data-theme="dark"]{
    --body-bg:#000; 
    --text-color:#fff;
    --para-color:"#bebbbb"
}

[data-theme="light"]{
    --body-bg:#f5f5f5;
    --text-color:#000;
    --text-color:#000;

}
