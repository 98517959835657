@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@300..700&family=Baloo+Thambi+2:wght@400..800&family=Catamaran:wght@100..900&family=Meera+Inimai&family=Noto+Sans+Tamil:wght@100..900&family=Pavanam&family=Tiro+Tamil:ital@0;1&display=swap');
body{
  font-family: "Meera Inimai", sans-serif !important;
}

p{
  font-size: 1rem;
  line-height: 30px;
}

@media (max-width: 768px) {
  p {
    line-height: 25px; /* Increase width for XXL screens */
  }
}


/* XL screens (1200px and up) */
@media (min-width: 1200px) {
  .container {
      max-width: 95% !important; /* Increase width for XL screens */
  }
}


@media (min-width: 1400px) {
  .container {
  max-width: 95% !important; 
  }
}
