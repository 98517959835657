body::-webkit-scrollbar {
  width: 3px;
}

/* Track */
body ::-webkit-scrollbar-track {
  background: #a10101;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #a10101;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: hsl(252, 4%, 56%);
}



@media (min-width : 1200px) {
  .header-sec .offcanvas {
    visibility: visible;
    position: relative;
    background: none;
    border: none;
    justify-content: end;
    color: red;
  }
}

@media (max-width : 1200px) {
  .header-sec .offcanvas {
    width: 282px !important;
  }

  .header-sec .offcanvas-start-xl {
    top: 0;
    left: 0;
    border-right: 1px solid rgba(0, 0, 0, .2);
    transform: translateX(-100%);
  }

  .form-btns {

    gap: 5px !important;
  }
}

.header-sec .navbar-nav li span {
  color: #1c1c1c !important;
  font-weight: 100;
  font-size: 16px;



}

.header-sec .navbar-brand img {
  width: 100%;
  max-width: 50px;
  max-height: 50px;
}

.header-sec .navbar-brand p {
  color: #a10101;
  font-size: 2vw;
 
  font-weight: 600;
}

.header-sec .nav-item {
  padding: 0 4px;
}

.header-sec nav.navbar.navbar-expand-xl.navbar-light {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* background-color: var(--body-bg) !important; */
  width: 100%;
  position: fixed;
  height: 65px;
  padding: 30px 30px;
  z-index: 99;
  background-color: #f8f5f5;
}

.dropdown .dropdown-menu {
  display: none;
}

.dropdown:hover>.dropdown-menu {
  display: block;
  animation: fadeInUp 0.3s ease-in-out forwards;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.header-sec .navbar-expand-xl .navbar-nav .dropdown-menu {
  background-color: #a10101;

}

.header-sec .navbar-expand-xl .navbar-nav .dropdown-menu li span {
  color: #ffff !important;
  cursor: pointer;
}

.header-sec .navbar-expand-xl .navbar-nav .dropdown-menu li span:hover {
  background-color: #ffe723;
  color: #000;
  cursor: pointer;
}
button.otp-section {
  background: linear-gradient(to right, #a10101 30%, #ff2323 100%, #a10101 50%);
  color: #fff;
  border: unset;
  border-radius: 5px;
  font-size: 15px;
}
@media only screen and (max-width: 1200px) {
  .header-sec .navbar-brand p {
    font-size: 12px;
  }

  .header-sec .offcanvas {
    background-color: #f5f5f5;
  }

  .header-sec .navbar-nav li span {
    color: #000 !important;
    font-size: 12px;
  }

  .header-sec .navbar-nav li .nav-link {
    font-size: 12px;
  }

  .joinbtn {
    font-size: 12px !important;
  }


  .header-sec nav.navbar.navbar-expand-xl.navbar-light {
    padding: 0;
  }

  .header-sec .offcanvas-body {
    padding: 0 5px;
  }

  .form-btns {
    padding: 5px 4px;
  }

  .header-sec .navbar-toggler {
    border: none;
  }
}

.offcanvas-title img {
  width: 100%;
  max-width: 50px;
}

.joinbtn {
  display: block;
  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
  color: #fff;
  background-size: 200% auto;
  border-radius: 5px;
  transition: 0.5s;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  background-image: linear-gradient(to right, #a10101 30%, #ffe723 100%, #a10101 50%);
  border: none;
  padding: 10px 10px;
}

.joinbtn:hover {
  background-position: right center;
}

.language-selector {
  border: none;
  background-color: #a10101;
  color: #fff;
  border-radius: 5px;
  height: 40px;
  margin-top: 10px;

  
  
  
}

.language-selector :focus{
  border: none;
}
.header-sec .navbar-nav li .nav-link {
  color: #000 !important;
  font-weight: 500;
}

/* sugadhevan */
.join-us .modal-header {
  color: #a10101;
  padding: 12px 12px;
}

.join-us .form-label {
  color: #a10101;

}

.join-us .form-control {
  border: 1px solid #e3e1e1;
  height: 40px;
  border-radius: 0;
}

.join-us .form-control:focus {
  border: 1px solid #a10101;
  box-shadow: unset;

}

.header-sec .navbar-toggler:focus {
  box-shadow: unset;
}

.join-us .nav-item .nav-link {
  background-color: transparent !important;
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

.join-us .nav-item .nav-link.active {
  color: #a10101;
  border: 1px solid #d5d5d5;
}

@media only screen and (max-width: 500px) {
  .join-us .nav-item .nav-link {
    font-size: 10px;
  }
  .modal-header .btn-close {
    font-size: 14px;
    margin-left: 15px !important;
  }
  .join-us .modal-header {
    padding: 12px 2px;
  }
}

.header-sec .dropdown-item:focus {
  background-color: unset !important;
}

.offcanvas-header .offcanvas-title {
  font-size: 12px;
  font-weight: 600;
}
.header-sec .navbar-expand-xl .navbar-nav .dropdown-menu li span:hover {
  color: #a10101 !important;
}